import React, { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useApp } from '@/core/contexts/app'
import Anchor from '@/components/base/anchor'
import { Column, Row, Container } from '@/components/base/gridview'
import Icon from '@/components/base/icon'
import Image from '@/components/base/image'
import pageService from '@/services/http/wap/page'

import { PanelProps } from './type'
import Button from '@/components/base/button'
import { path } from '@/core/utils'

const Panel: React.FunctionComponent<PanelProps> = ({
  children,
  isOpen,
  logo,
}) => {
  const app = useApp()
  const router = useRouter()

  const handleLanguageSelection = (language: string) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        router.push(path.app(app.environment, slug))
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
  }

  const renderLanguageSelection = () => {
    if (app.languages.length > 1) {
      return (
        <div className="asg-header-language-selection">
          <div className="asg-header-language-selection-current">
            {app.language.substring(0, 2)}
          </div>
          <div className="asg-header-language-selection-dropdown">
            <div className="asg-header-language-selection-dropdown-list">
              {app.languages.map((language) => {
                return (
                  <li key={language.id}>
                    <Button
                      onClick={() => handleLanguageSelection(language.id)}
                    >
                      {language.title}
                    </Button>
                  </li>
                )
              })}
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={`panel panel--${isOpen ? 'show' : 'hide'}`}>
      <div className="panel-body" style={{ position: 'relative', flex: 1 }}>
        {children}
      </div>
    </div>
  )
}

export default Panel
