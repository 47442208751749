import React, { useState, useEffect } from 'react'

import { useApp } from '@/core/contexts/app'
import Anchor from '@/components/base/anchor'
import Icon from '@/components/base/icon'
import { NavigationReference } from '@/services/http/wap/types'

import Panel from './Panel'
import { NavigationBreadcrumb, NavigationProps } from './type'
import { classnames } from 'begonya/functions'
import { Column, Row } from '@/components/base/gridview'
import IconList from '@/components/base/icon-list/IconList'
import { IconListItem } from '@/components/base/icon-list'

const Navigation: React.FunctionComponent<NavigationProps> = ({
  data,
  isOpen,
  headerHeight,
}) => {
  const [activeSubMenu, setActiveSubMenu] = useState<number>(-1)

  const app = useApp()

  const handleOpen = (index: number) => {
    setActiveSubMenu(index)
  }

  const renderNavs = (navigation: NavigationReference[], index?: number) => {
    const isChildren = index && typeof index === 'number'
    const isOpen = index === activeSubMenu
    const isMain = activeSubMenu === -1 && !isChildren

    return (
      <React.Fragment>
        <nav
          className={classnames(
            'navigation-menu',
            (isOpen || isMain) && 'navigation-menu--active',
            !isChildren && 'navigation-menu--main'
          )}
        >
          <ul>
            {navigation.map((menuItem, index) => {
              const title = menuItem.title
              if (menuItem.children.length) {
                return (
                  <li key={index} className={menuItem.className}>
                    <Anchor
                      onClick={() => handleOpen(index)}
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <Icon
                      name="icon-arrow-right"
                      className="menu-item-arrow"
                      size="small"
                    />
                  </li>
                )
              }

              return (
                <li key={index} className={menuItem.className}>
                  {menuItem.href.indexOf('http') === 0 ? (
                    <a
                      href={menuItem.href}
                      target={menuItem.target}
                      dangerouslySetInnerHTML={{ __html: title }}
                      rel="noreferrer"
                    />
                  ) : (
                    <Anchor
                      className={classnames(
                        app.page.path === menuItem.href && 'current'
                      )}
                      href={menuItem.href}
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  )}
                </li>
              )
            })}
          </ul>
        </nav>
      </React.Fragment>
    )
  }

  const renderContent = () => {
    return (
      <div className="navigation-content scrollbar">
        {data.map((item, index) => {
          if (item.children && item.children.length) {
            return (
              <>
                <button
                  className={classnames(
                    'back-button',
                    activeSubMenu !== -1 ? 'active' : 'disable'
                  )}
                  onClick={() => setActiveSubMenu(-1)}
                >
                  <Icon name="reply"></Icon>
                </button>
                {renderNavs(item.children, index)}
              </>
            )
          }
        })}

        {renderNavs(data)}
      </div>
    )
  }

  return (
    <Panel isOpen={isOpen}>
      <div className="navigation">
        <div className="navigation-in">
          {renderContent()}
          <footer className="navigation-footer">
            <div className="navigation-footer-contact">
              <div className="title">Çağrı Merkezi</div>
              <Anchor href="tel:0850 850 09 41">0850 850 09 41</Anchor>
            </div>
            <Row xs={{ align: 'center', justify: 'end' }}>
              <Column>
                <IconList className="social-media">
                  {app.settings.socials['facebook'] && (
                    <IconListItem
                      icon={{
                        name: 'icon-facebook',
                        onClick: () =>
                          window.open(
                            app.settings.socials['facebook'] ||
                              'https://www.facebook.com/asggrup',
                            '_blank'
                          ),
                        style: { cursor: 'pointer' },
                      }}
                    />
                  )}
                  {app.settings.socials['twitter'] && (
                    <IconListItem
                      icon={{
                        name: 'icon-twitter',
                        onClick: () =>
                          window.open(
                            app.settings.socials['twitter'] ||
                              'https://www.twitter.com/asggrup',
                            '_blank'
                          ),
                        style: { cursor: 'pointer' },
                      }}
                    />
                  )}
                  {app.settings.socials['instagram'] && (
                    <IconListItem
                      icon={{
                        name: 'icon-instagram',
                        onClick: () =>
                          window.open(
                            app.settings.socials['instagram'] ||
                              'https://www.instagram.com/asggrup/',
                            '_blank'
                          ),
                        style: { cursor: 'pointer' },
                      }}
                    />
                  )}
                  {app.settings.socials['youtube'] && (
                    <IconListItem
                      icon={{
                        name: 'icon-youtube',
                        onClick: () =>
                          window.open(
                            app.settings.socials['youtube'] ||
                              'https://www.linkedin.com/asggrup',
                            '_blank'
                          ),
                        style: { cursor: 'pointer' },
                      }}
                    />
                  )}
                  {app.settings.socials['linkedin'] && (
                    <IconListItem
                      icon={{
                        name: 'icon-linkedin2',
                        onClick: () =>
                          window.open(
                            app.settings.socials['linkedin'] ||
                              'https://www.linkedin.com/asggrup',
                            '_blank'
                          ),
                        style: { cursor: 'pointer' },
                      }}
                    />
                  )}
                </IconList>
              </Column>
            </Row>
          </footer>
        </div>
      </div>
    </Panel>
  )
}

export default Navigation
