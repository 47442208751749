import React, { useEffect, useState } from 'react'
import Router, { useRouter } from 'next/router'
import { classnames } from 'begonya/functions'
import Navigation from './Navigation'

import { path } from '@/core/utils'
import { useApp } from '@/core/contexts/app'
import { useWidth } from '@/core/hooks/useWidthResize'

import pageService from '@/services/http/wap/page'

import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image'
import Anchor from '@/components/base/anchor/Anchor'
import Button from '@/components/base/button'
import Icon from '@/components/base/icon/Icon'

import { HeaderProps } from './type'

const Header: React.FunctionComponent<HeaderProps> = ({
  logo,
  navigation,
  isHome,
}) => {
  const app = useApp()
  const width = useWidth()
  const router = useRouter()
  const [headerHeight, setHeaderHeight] = useState(0)
  const [panel, setPanel] = useState('')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [menuActive, setMenuActive] = useState(null)

  const handleLanguageSelection = (language: string) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        router.push(path.app(app.environment, slug))
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
  }

  const openLanguage = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleClick = (index: any) => {
    setMenuActive((prevIndex) => (prevIndex === index ? null : index))
  }

  const renderLanguageSelection = () => {
    if (app.languages.length > 1) {
      return (
        <div className="header-body-language-selection">
          <ul className="header-body-language-selection-list">
            {width <= 768 &&
              app.languages.map((language) => {
                if (app.language === language.name) {
                  return (
                    <li
                      key={language.id}
                      className={classnames(panel === 'menu' && 'active')}
                    >
                      <Button onClick={openLanguage} className="current">
                        {language.name.substring(0, 2)}
                      </Button>
                    </li>
                  )
                } else {
                  return null // Return null or other JSX if needed
                }
              })}
            {width <= 768 && isDropdownOpen && (
              <div className="ggm-header-body-language-selection-list-dropdown">
                <ul className="ggm-header-body-language-selection-list-dropdown-list">
                  {app.languages.map((language) => (
                    <li
                      key={language.id}
                      className={classnames(panel === 'menu' && 'active')}
                    >
                      <Button
                        onClick={() => handleLanguageSelection(language.id)}
                      >
                        {language.title}
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {width > 768 &&
              app.languages.map((language) => (
                <li
                  key={language.id}
                  className={classnames(panel === 'menu' && 'active')}
                >
                  <Button
                    onClick={() => handleLanguageSelection(language.id)}
                    className={classnames(
                      app.language === language.name && 'current'
                    )}
                  >
                    {language.name.substring(0, 2)}
                  </Button>
                </li>
              ))}
          </ul>
        </div>
      )
    }
  }

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      document.body.style.overflow = ''
      setPanel('')
    }

    Router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return function cleanup() {
      Router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [])

  return (
    <React.Fragment>
      <header className={classnames('header', !isHome && 'header--not-home')}>
        <div
          className={classnames(
            'header-body',
            app.theme.title === 'Light' && 'reverse'
          )}
        >
          <Container size="fluid">
            <Row xs={{ justify: 'between', align: 'center' }}>
              <Column>
                <Anchor
                  className="header-body-logo"
                  href={app.settings.homepage}
                >
                  <Image
                    {...(logo?.src ? logo : app.theme.logo)}
                    alt={app.page.title}
                  />
                </Anchor>
              </Column>
              {width > 960 && (
                <Column>
                  <nav className="header-body-desktop-menu">
                    <ul>
                      {navigation?.map((menuItem, index) => (
                        <li
                          key={index}
                          className={classnames(
                            menuItem.className,
                            menuItem.children.length && 'hasSubMenu',
                            menuActive === index && 'active'
                          )}
                        >
                          <div
                            onClick={() => handleClick(index)}
                            onKeyDown={() => handleClick(index)}
                            role="button"
                            tabIndex={0}
                          >
                            {menuItem.children.length ? (
                              <Anchor onClick={(e) => e.preventDefault()}>
                                {menuItem.title}
                                <Icon name="keyboard-arrow-down" />
                              </Anchor>
                            ) : (
                              <Anchor
                                href={menuItem.href}
                                dangerouslySetInnerHTML={{
                                  __html: menuItem.title,
                                }}
                              />
                            )}
                            {menuItem.children.length ? (
                              <ul className="sub-menu">
                                {menuItem.children.map((subItem, subIndex) => (
                                  <li key={subIndex}>
                                    <Anchor href={subItem.href}>
                                      {subItem.title}
                                    </Anchor>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <></>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </Column>
              )}
              <Column className="d-flex">
                <div>{renderLanguageSelection()}</div>
                <div className="d-flex">
                  <Button
                    className={classnames(
                      'header-body-menu-button',
                      panel === 'menu' && 'active'
                    )}
                    onClick={() => {
                      panel === 'menu' ? setPanel('') : setPanel('menu')
                    }}
                  >
                    {panel == 'menu' ? (
                      <Icon name="cancel" size="medium" />
                    ) : (
                      <Icon name="icon-menu" size="medium" />
                    )}
                  </Button>
                </div>
              </Column>
            </Row>
          </Container>
        </div>
        {navigation ? (
          <Navigation
            isOpen={panel === 'menu'}
            data={navigation}
            logo={logo}
            headerHeight={headerHeight}
          />
        ) : null}
      </header>
    </React.Fragment>
  )
}

export default Header
